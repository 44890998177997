@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.satoshi{
  font-family: 'Satoshi', sans-serif;
}

.cabinet-grotesk{
  font-family: 'Cabinet Grotesk', sans-serif;
}

.bounce{
  animation: bounce infinite ease-in-out 2s alternate-reverse;
}

@keyframes bounce {
  0%{
    transform: translate(-5px, -10px);
  }
  100%{
    transform: translate(0px, 0px);
  }
}

.moving{
  transform: translateX(-100%);
  animation: moving 25s linear infinite;
}

@keyframes moving {
  0%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(-200%);
  }
}